@use '@angular/material' as mat;
@import "../../var";

.vex-style-dark {
  @include mat.all-component-themes($vex-dark-theme);

  // Foreground
  // --background-app-bar: #{map-get(map-get($vex-dark-theme, background), app-bar)};
  --background-app-bar: #060606 !important;

  // Background
  --footer-background: var(--background-foreground);
  --navigation-background: #{map-get(map-get($vex-dark-theme, background), card)};
  --toolbar-background: #{map-get(map-get($vex-dark-theme, background), card)};
  // --background-base: #{map-get(map-get($vex-dark-theme, background), background)};
  --background-base: #6E6E6E !important;

  // Colors
  --background-foreground: #{map-get(map-get($vex-dark-theme, background), card)};
  --background-foreground-rgb: #{red(map-get(map-get($vex-dark-theme, background), card))}, #{green(map-get(map-get($vex-dark-theme, background), card))}, #{blue(map-get(map-get($vex-dark-theme, background), card))};
  --footer-color: var(--text-color);
  --navigation-color: var(--text-color);
  --text-color: #{$light-primary-text};
  --toolbar-color: #{$light-primary-text};
  --text-color-light: #{$dark-primary-text};

  // Toolbar
  --foreground-divider: #{map-get(map-get($vex-dark-theme, foreground), divider)};
  --text-hint: #{$light-disabled-text};

  // Navigation
  --text-hint-light: #{$dark-disabled-text};
  --background-hover: #{map-get(map-get($vex-dark-theme, background), hover)};

  // Secondary Toolbar
  --text-secondary: #{$light-secondary-text};

  // Footer
  --text-secondary-light: #{$dark-secondary-text};
  --secondary-toolbar-background: var(--background-foreground);

  --sidenav-item-background-hover: #9F9F9F !important;
  --sidenav-toolbar-background: #3B3B3B !important;
  --sidenav-background: #3B3B3B !important;
  --sidenav-toolbar-background-active: #A1A1A1 !important;
  --sidenav-item-background-active: #A1A1A1 !important;
  --background-foreground-rgb: #3B3B3B !important;
  --background-hover: #A1A1A1 !important;
}
