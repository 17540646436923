


@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core.scss";


@import '~quill/dist/quill.snow.css';

:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}

.vex-page-layout-header {
  height: 100px !important;
}

.mat-progress-bar-buffer {
  background-color: white;
}

.mat-dialog-actions {
  padding: 0px 0 !important;
  // min-height: 0px !important;
  margin-bottom: 0px !important;
}

.mat-dialog-container {
  padding: 15px 24px !important;
}

.mat-dialog-title {
  margin: 0 0 10px !important;
}

.cdk-global-scrollblock {
  overflow-y: hidden
}

.justify-content-end {
  justify-content: end;
}

.header-with-btn {
  justify-content: space-between;
  align-items: center;
}

.filter {
  width: 100%;  
}

.link {
  color: #ffc107;
  font-weight: 500;
}

.text-decoration-underline {
  text-decoration: underline;
}

.log-ul {
  list-style: inside;
  width: 100%;
  max-height: 70vh;
  overflow: auto !important;
}

.head.bg-app-bar {
  height: auto !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

@media (min-width:600px) {
  .m-flex {
      display: flex;
  }
  .m-block {
    display: block;
  }
  .w-200 {
    width: 200px;
  }
  .w-245 {
    width: 245px
  }
  .mmb-6 {
    margin-bottom: 1.5rem;
  }
  .limit-type {
    padding-top: 20px;
  }
}

@media (max-width: 600px) {
  .mb-15 {
    margin-bottom: 15px !important;
  }
  .limit-type {
    padding-top: 18px;
    padding-left: 20px;
  }
  .headline {
    font-size: 16px;
  }
  .w-51 {
    width: 51vw;
  }
  .mobile-screen {
    display: none !important;
  }
}

@media (min-width: 600px) and (max-width:1020px){ 
  .format {
    display: grid;
  }
  .limit-type {
    padding-top: 0px;
  }
}

div p {
  word-break: break-all;
}


.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: rgba(82, 63, 105, 0.42) !important;
}

.mat-input-element:disabled {
  color: currentColor !important;
}

.h-full-vh {
  max-height: 90vh;
}

span.tag {
  color: #F2F2F2;
  padding: 4px 15px;
  width: fit-content;
  border-radius: 5px;
  cursor: pointer;

  &.yes {
    background-color: #14A44D;
   }
   
   &.no {
       background-color: #f44336;
  }
}

.bg-foreground {
  background-color: var(--sidenav-background) !important;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url('assets/images/times-circle.svg') no-repeat 50% 50%;
  background-size: contain;
  cursor: pointer;
}

.vex-style-dark {
  .mat-table {
    background-color: var(--sidenav-toolbar-background) !important;
  }
  .mat-card {
    background-color: var(--sidenav-toolbar-background) !important;
  }
  .mat-paginator {
    background-color: var(--sidenav-toolbar-background) !important;
  }
  .mat-menu-panel {
    background-color: var(--sidenav-toolbar-background) !important;
  }
  .mat-datepicker-content {
    background-color: var(--sidenav-toolbar-background) !important;
  }
  .mat-select-panel {
    background-color: var(--sidenav-toolbar-background) !important;
  }
  .mat-dialog-container {
    background-color: var(--sidenav-toolbar-background) !important;
  }
  input[type="search"]::placeholder {
    color: #F2F2F2;
  }
  input[type="search"]::-webkit-search-cancel-button {
    filter: invert(1);
  }
  .search-icon {
    color: #F2F2F2 !important;
  }
  .disabled-row {
    background: #a1a1a1;
  }
  ng-otp-input .ng-otp-input-wrapper .otp-input {
    background: #4f4f4f;
  }
}

.disabled-row {
  background: #ebebee;
}

.pointer-event-none {
  pointer-events: none;
}

.snack-success {
  color: #FBFBFB !important;
  background-color: #14A44D !important;
}

.snack-warning {
  color: #FBFBFB !important;
  background-color: #f44336 !important;
}

simple-snack-bar .mat-simple-snackbar-action button .mat-button-wrapper {
  color: #FBFBFB !important;
}

.text-bold {
  font-weight: bold;
}

.m-height {
  max-height: 500px;
}

.filter-checkbox mat-checkbox .mat-checkbox-layout {
  white-space: break-spaces;
  display: contents;
}

.inline-flex {
  display: inline-flex;
}

.custom-tooltip{
  max-width: 550px !important;
  max-height: 200px;
  font-size: 14px;
}


.mat-calendar-body-cell:hover>.mat-calendar-body-cell-content {
  background-color: rgba(var(--color-primary), 1) !important;
  color: rgba(var(--color-primary-contrast), 1) !important;
}

.mat-calendar-body-cell {
  .mat-calendar-body-today {
    background-color: rgba(var(--color-primary-contrast), 1) !important;
  }
  .mat-calendar-body-selected {
    background-color: rgba(var(--color-primary), 1) !important;
    color: rgba(var(--color-primary-contrast), 1) !important;
  }
}

.no-data-found {
  text-align: center;
}

.new-addition{
  padding: 2px 4px 0px 4px;
  background-color: green;
  border-radius: 5px;
  color: white;
  font-size: 9px;
  display: flow;
  width: 27px;
  height: 18px;
}